// ** Routes Imports
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';

// ** Default Route
const DefaultRoute = '/admin/matches';

// ** Merge Routes
const Routes = [...MainRoutes, ...AuthRoutes];

export { DefaultRoute, Routes };
