import { ACTION_TYPES } from '@store/actionTypes';

// **  Initial State
const initialState = {
  tournamentOptions: [],
};

const tournamentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_TOURNAMENT_OPTIONS:
      return {
        ...state,
        tournamentOptions: action.data.tournamentOptions,
      };
    default:
      return state;
  }
};

export default tournamentReducer;
