import { ACTION_TYPES } from '@store/actionTypes';
import { getAllTournamentsAPI } from '@api/main';
import { arrayToSelectOptions } from '@utility/utils';

// ** Get all user
export const getAllTournamentOptionsAC = () => {
  return async (dispatch) => {
    try {
      const res = await getAllTournamentsAPI();
      const tournamentOptions = arrayToSelectOptions(res, 'tournamentName', 'tournamentId');

      dispatch({
        type: ACTION_TYPES.UPDATE_TOURNAMENT_OPTIONS,
        data: {
          tournamentOptions,
        },
      });
    } catch (error) {
      console.log('🚀 ~ file: tournament.js:19 ~ return ~ error:', error);
    }
  };
};
