// components
import SettingsIcon from '@mui/icons-material/Settings';
import FolderIcon from '@mui/icons-material/Folder';

// ----------------------------------------------------------------------

const sidebarConfig = [
  // SYSTEM ADMINISTRATION
  // ----------------------------------------------------------------------
  {
    subheader: 'Match manager',
    items: [
      {
        title: 'Quản lý trận đấu',
        path: '/admin/matches',
        icon: <FolderIcon fontSize="small" />,
      },
      {
        title: 'Quản lý giải đấu',
        path: '/admin/tournaments',
        icon: <FolderIcon fontSize="small" />,
      },
      {
        title: 'Quản lý video',
        path: '/admin/videos',
        icon: <FolderIcon fontSize="small" />,
      },
    ],
  },
  {
    subheader: 'Content manager',
    items: [
      {
        title: 'Quản lý SEO',
        path: '/admin/page-seo-settings',
        icon: <FolderIcon fontSize="small" />,
      },
      {
        title: 'Quản lý banner',
        path: '/admin/banners',
        icon: <FolderIcon fontSize="small" />,
      },
      {
        title: 'Quản lý bài viết',
        path: '/admin/articles',
        icon: <FolderIcon fontSize="small" />,
      },
      // {
      //   title: 'Quản lý group',
      //   path: '/video-management',
      //   icon: <FolderIcon fontSize="small" />,
      // },
      // {
      //   title: 'Quản lý user',
      //   path: '/user-management',
      //   icon: <SettingsIcon fontSize="small" />,
      // },
      // {
      //   title: 'Cấu hình website',
      //   path: '/video-management',
      //   icon: <SettingsIcon fontSize="small" />,
      // },
      {
        title: 'View logs',
        path: '/admin/view-logs',
        icon: <SettingsIcon fontSize="small" />,
      },
      {
        title: 'Đổi mật khẩu',
        path: '/change-password',
        icon: <SettingsIcon fontSize="small" />,
      },
    ],
  },
];

export default sidebarConfig;
