// ----------------------------------------------------------------------

import useSettings from '@hooks/useSettings';

export default function Card(theme) {
  const { themeLayout } = useSettings();
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: theme.customShadows?.card,
          borderRadius: Number(theme.shape?.borderRadius) * 2,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          '&.card-container': {
            '@media (min-width: 0px)': {
              minHeight: `${window.innerHeight - 206}px`,
            },
            '@media (min-width: 600px)': {
              minHeight: `${window.innerHeight - 206}px`,
            },
            '@media (min-width: 900px)': {
              minHeight: `${window.innerHeight - 212}px`,
            },
            '@media (min-width: 1200px)': {
              minHeight: themeLayout === 'vertical' ? `${window.innerHeight - 270}px` : `${window.innerHeight - 240}px`,
            },
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  };
}
