// @mui
import { Stack, Button } from '@mui/material';
// redux
import { useDispatch } from 'react-redux';
import { logoutAC } from '@store/actions/auth';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const dispatch = useDispatch();
  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <Button variant="contained" onClick={() => dispatch(logoutAC())}>
        Đăng xuất
      </Button>
    </Stack>
  );
}
