// routes
import Router from './router/Router';
// theme
import ThemeProvider from './theme';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import { ConfirmationDialogProvider } from '@utility/contexts/ConfirmationDialog';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import locale from 'date-fns/locale/en-US';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <ThemeColorPresets>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ConfirmationDialogProvider>
                  <ProgressBarStyle />
                  <ScrollToTop />
                  <Router />
                </ConfirmationDialogProvider>
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeColorPresets>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
