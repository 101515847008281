// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './reducers/auth';
import tournament from './reducers/tournament';

const rootReducer = combineReducers({
  auth,
  tournament,
});

export default rootReducer;
