import { lazy } from 'react';

const MainRoutes = [
  {
    path: '/admin/matches',
    exact: true,
    component: lazy(() => import('@pages/matches')),
  },
  {
    path: '/change-password',
    exact: true,
    component: lazy(() => import('@pages/change-password')),
  },
  {
    path: '/admin/tournaments',
    exact: true,
    component: lazy(() => import('@pages/tournaments')),
  },
  {
    path: '/admin/videos',
    exact: true,
    component: lazy(() => import('@pages/videos')),
  },
  {
    path: '/admin/view-logs',
    exact: true,
    component: lazy(() => import('@pages/viewlogs')),
  },
  {
    path: '/admin/page-seo-settings',
    exact: true,
    component: lazy(() => import('@pages/page-seo-settings')),
  },
  {
    path: '/admin/articles',
    exact: true,
    component: lazy(() => import('@pages/articles')),
  },
  {
    path: '/admin/banners',
    exact: true,
    component: lazy(() => import('@pages/banners')),
  },
];

export default MainRoutes;
