import { Storage, STORAGE_KEYS } from '@utility/storage';
import { ACTION_TYPES } from '@store/actionTypes';
import { getAllUserAPI, refreshToken } from '@api/main';

// ** Handle User Login
export const loginAC = (data) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.LOGIN,
      data,
    });

    // ** Add to user, account, accessToken & refreshToken to localStorage
    Storage.setItem(STORAGE_KEYS.userData, data.user);
    Storage.setItem(STORAGE_KEYS.token, data.token);
  };
};

/**
 * Handle User Logout
 * @param {boolean} isSessionExpired
 */
export const logoutAC = () => {
  return (dispatch) => {
    dispatch({ type: ACTION_TYPES.LOGOUT });

    // ** Remove user, accessToken & refreshToken from localStorage
    Storage.clear(STORAGE_KEYS.userData);
    Storage.clear(STORAGE_KEYS.token);
  };
};

// ** Check remenbered user
export const checkRememberedUserAC = () => {
  return async (dispatch) => {
    try {
      if (!!Storage.getItem(STORAGE_KEYS.userData) && !!Storage.getItem(STORAGE_KEYS.token)) {
        await refreshToken();
        dispatch({
          type: ACTION_TYPES.CHECK_REMENBERED_USER_DONE,
          data: {
            isUserLoggedIn: true,
            user: Storage.getItem(STORAGE_KEYS.userData),
          },
        });
      } else {
        dispatch({
          type: ACTION_TYPES.CHECK_REMENBERED_USER_DONE,
          data: {
            isUserLoggedIn: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.CHECK_REMENBERED_USER_DONE,
        data: {
          isUserLoggedIn: false,
          user: null,
        },
      });
    }
  };
};

// ** Get all user
export const getAllUserAC = () => {
  return async (dispatch) => {
    try {
      const res = await getAllUserAPI();

      dispatch({
        type: ACTION_TYPES.UPDATE_USERS,
        data: {
          users: res.data,
        },
      });
      Storage.setItem(STORAGE_KEYS.users, {
        ...res.data,
      });
    } catch (error) {
      console.log('🚀 ~ file: auth.js:83 ~ return ~ error:', error);
    }
  };
};
