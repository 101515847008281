// scroll bar
import 'simplebar/src/simplebar.css';

// highlight
import '@utility/highlight';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// ** Yup intl message
import '@utility/yupConfig';

// editor
import 'react-quill/dist/quill.snow.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/store';
// contexts
import { SettingsProvider } from '@utility/contexts/SettingsContext';
import { CollapseDrawerProvider } from '@utility/contexts/CollapseDrawerContext';
import { IntlProviderWrapper } from '@utility/contexts/Internationalization';
//
import App from './App';

// ----------------------------------------------------------------------

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <IntlProviderWrapper>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </IntlProviderWrapper>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);
