import axios, { handleRefreshToken } from './base';

// #region AUTHENTICATION API

/**
 * Login
 * @param {{
  userName: string;
  password: string;
}} data
*/
export const loginAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data, { __auth: false });
};

export const healthCheckAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/health-check-patient`);
};

export const refreshToken = async () => {
  return handleRefreshToken();
};

// #endregion

// #region MATCHES

export const searchMatchesAPI = async (pageSize, pageNumber, filter) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/matches/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter
  );
};

export const createMatchAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/matches`, data);
};

export const updateMatchAPI = async (matchId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/matches/${matchId}`, data);
};

export const getDetailsMatchAPI = async (matchId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/matches/${matchId}`);
};

export const setStatusMatchAPI = async (matchId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/matches/${matchId}/set-status`, data);
};

// #endregion

// #region Tournaments

export const searchTournamentsAPI = async (pageSize, pageNumber, filter = {}) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/tournaments/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter
  );
};

export const getAllTournamentsAPI = async (pageNumber = 1) => {
  let arr = [];
  const apiRes = await searchTournamentsAPI(100, pageNumber);
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData);
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nextPageRes = await getAllTournamentsAPI(pageNumber + 1);
      arr = [...arr, ...nextPageRes];
    }
  }
  return arr;
};

export const setStatusTournamentAPI = async (tournamentId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/set-status`, data);
};

// #endregion

// #region USERS
export const getAllUserAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/users`);
};

export const changePasswordAPI = async (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/change-password`, data);
};
// #endregion

// #region TEAMS
export const getTeamsAPI = async (pageSize, pageNumber) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/teams/search?pageSize=${pageSize}&pageNumber=${pageNumber}`);
};

export const getAllTeamsAPI = async (pageNumber = 1) => {
  let arr = [];
  const apiRes = await getTeamsAPI(100, pageNumber);
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData);
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nextPageRes = await getAllTeamsAPI(pageNumber + 1);
      arr = [...arr, ...nextPageRes];
    }
  }
  return arr;
};
// #endregion

// #region Videos

export const searchVideosAPI = async (dateTimeUnix, tournamentId = '', pageSize = 1, pageNumber = 50, keyword = '') => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/videos/search?dateTimeUnix=${dateTimeUnix}&tournamentId=${tournamentId}&pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`
  );
};

export const creatVideoAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/videos`, data);
};

export const getVideoDetailsAPI = async (videoId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/videos/${videoId}`);
};

export const updateVideoAPI = async (videoId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/videos/${videoId}`, data);
};

export const updateEnableVideoAPI = async (videoId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/videos/${videoId}/set-enabled`, data);
};

export const deleteVideoAPI = async (videoId) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/videos/${videoId}`);
};

// #endregion

// #region View Logs

export const getViewLogsAPI = async (fromDate, toDate, userId = '', pageSize = 50, pageNumber = 1) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/activity-logs/search?fromDate=${fromDate}&toDate=${toDate}&userId=${userId}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
};

// #endregion

// #region Page SEO settings

export const getPageSeoSettingsAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/page-seo-settings/all`);
};

export const getPageSeoDetailsAPI = async (pageId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/page-seo-settings/${pageId}`);
};

export const updatePageSeoAPI = async (pageId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/page-seo-settings/${pageId}`, data);
};

// #endregion

// #region Articles

export const createArticleAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/articles`, data);
};

export const searchArticleAPI = async (keyword, dateTimeUnix, pageSize = 50, pageNumber = 1) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/articles?keyword=${keyword}&dateTimeUnix=${dateTimeUnix}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
};

export const deleteArticleAPI = async (articleId) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/articles/${articleId}`);
};

export const getArticleDetailsAPI = async (articleId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/articles/${articleId}`);
};

export const updateArticleAPI = async (articleId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/articles/${articleId}`, data);
};

// #endregion

// #region Physical File
export const uploadImageAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/files`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// #endregion

// #region Banners

export const createBannerAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/banners`, data);
};

export const searchBannersAPI = async (pageSize = 50, pageNumber = 1) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/banners?pageSize=${pageSize}&pageNumber=${pageNumber}`);
};

export const deleteBannerAPI = async (bannerId) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/banners/${bannerId}`);
};

export const getBannerDetailsAPI = async (bannerId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/banners/${bannerId}`);
};

export const updateBannerAPI = async (bannerId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/banners/${bannerId}`, data);
};

// #endregion
